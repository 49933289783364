import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_adm_input = _resolveComponent("adm-input")!

  return (_openBlock(), _createBlock(_component_adm_input, _mergeProps({
    placeholder: _ctx.$t('2-stepSignUpForm.all-step-1-signup-email-placeholder'),
    label: _ctx.$t('2-stepSignUpForm.all-step-1-signup-email-field'),
    id: "field_email",
    "force-id": "",
    onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.emailValidation.$touch())),
    error: _ctx.emailErrors
  }, _ctx.$attrs), null, 16, ["placeholder", "label", "error"]))
}