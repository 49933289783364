import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_adm_phone = _resolveComponent("adm-phone")!

  return (_openBlock(), _createBlock(_component_adm_phone, _mergeProps({
    phoneLabel: _ctx.$t('2-stepSignUpForm.all-step-signup-phone-field'),
    countryLabel: _ctx.$t('2-stepSignUpForm.all-step-2-signup-country-field'),
    countries: _ctx.countryPhones,
    id: "field_phone",
    "force-id": "",
    onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.phoneValidation.$touch())),
    error: _ctx.phoneErrors
  }, _ctx.$attrs), null, 16, ["phoneLabel", "countryLabel", "countries", "error"]))
}