
import { computed, defineComponent, toRefs, PropType, ref } from "vue";
import { Validation } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { AdmInput, AdmTypography, AdmIcon } from "@/components";

export default defineComponent({
  name: "PasswordField",
  components: {
    AdmInput,
    AdmTypography,
    AdmIcon,
  },
  props: {
    passwordValidation: {
      type: Object as PropType<Validation>,
      required: true,
    },
    apiErrors: {
      type: Array as PropType<string[]>,
    },
    hints: {
      type: Array as PropType<Array<{ label: string; valid: boolean }>>,
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { passwordValidation } = toRefs(props);
    const typePassword = ref(true);

    const passwordErrors = computed(() => {
      const error = t(
        "2-stepSignUpForm.all-step-2-signup-password-field-error-4"
      );

      if (passwordValidation.value.$errors.length) {
        return error;
      }

      return null;
    });

    return { passwordErrors, typePassword };
  },
});
