import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e6182684"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_adm_typography = _resolveComponent("adm-typography")!
  const _component_adm_select = _resolveComponent("adm-select")!
  const _component_message_box = _resolveComponent("message-box")!
  const _component_email_field = _resolveComponent("email-field")!
  const _component_phone_field = _resolveComponent("phone-field")!
  const _component_password_field = _resolveComponent("password-field")!
  const _component_CheckboxField = _resolveComponent("CheckboxField")!
  const _component_info_box = _resolveComponent("info-box")!
  const _component_adm_button = _resolveComponent("adm-button")!
  const _component_adm_card = _resolveComponent("adm-card")!

  return (_openBlock(), _createBlock(_component_adm_card, { class: "signup-widget__form" }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmit && _ctx.handleSubmit(...args)), ["prevent"]))
      }, [
        _createVNode(_component_adm_typography, {
          color: "secondary",
          class: "signup-widget__header",
          variant: "h4"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("2-stepSignUpForm.all-step-1-signup-h2-v2", { companyName: _ctx.companyName })), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_adm_select, {
          label: _ctx.$t('2-stepSignUpForm.all-step-2-signup-country-residence'),
          placeholder: _ctx.$t('2-stepSignUpForm.all-step-2-signup-country-option'),
          options: _ctx.countryOptions,
          id: "field_country",
          "force-id": "",
          modelValue: _ctx.country,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.country) = $event))
        }, null, 8, ["label", "placeholder", "options", "modelValue"]),
        (_ctx.onlyDemo)
          ? (_openBlock(), _createBlock(_component_message_box, { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t(
            "2-stepSignUpForm.all-step-signup-country-restriction-notification"
          )), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_email_field, {
          modelValue: _ctx.state.email,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.email) = $event)),
          "email-validation": _ctx.validation.email,
          "api-errors": _ctx.apiErrors.entity
        }, null, 8, ["modelValue", "email-validation", "api-errors"]),
        _createVNode(_component_phone_field, {
          phone: _ctx.state.phone,
          "onUpdate:phone": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.phone) = $event)),
          code: _ctx.state.code,
          "onUpdate:code": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.code) = $event)),
          "phone-validation": _ctx.validation.phone,
          "code-validation": _ctx.validation.code,
          "api-errors": _ctx.apiErrors.phone_number
        }, null, 8, ["phone", "code", "phone-validation", "code-validation", "api-errors"]),
        _createVNode(_component_password_field, {
          modelValue: _ctx.state.password,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.password) = $event)),
          "password-validation": _ctx.validation.password,
          "api-errors": _ctx.apiErrors.password,
          hints: _ctx.passwordHints
        }, null, 8, ["modelValue", "password-validation", "api-errors", "hints"]),
        (_ctx.signupCheckbox)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              _createVNode(_component_CheckboxField, {
                id: "btn_sign_up_checkbox",
                modelValue: _ctx.state.marketing_emails_enabled,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.marketing_emails_enabled) = $event))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t(
              "2-stepSignUpForm.all-step-signup-company-promotions-tickbox-v2",
              { companyName: _ctx.companyName }
            )), 1)
                ]),
                _: 1
              }, 8, ["modelValue"]),
              _createElementVNode("p", {
                class: "signup-widget__warning",
                innerHTML: _ctx.privacyPolicyMessage
              }, null, 8, _hoisted_1)
            ], 64))
          : (_openBlock(), _createBlock(_component_info_box, { key: 2 }, {
              default: _withCtx(() => [
                _createElementVNode("span", { innerHTML: _ctx.signupWarning }, null, 8, _hoisted_2)
              ]),
              _: 1
            })),
        (_ctx.formError)
          ? (_openBlock(), _createBlock(_component_adm_typography, {
              key: 3,
              variant: "body2",
              class: "signup-widget__error",
              color: "on-surface",
              "gutter-bottom": "",
              innerHTML: _ctx.formError
            }, null, 8, ["innerHTML"]))
          : _createCommentVNode("", true),
        _createVNode(_component_adm_button, {
          id: "btn_sign_up_signup_form",
          "force-id": "",
          variant: "contained",
          disabled: _ctx.disabled,
          "full-width": ""
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("2-stepSignUpForm.all-step-signup-cta")), 1)
          ]),
          _: 1
        }, 8, ["disabled"])
      ], 32)
    ]),
    _: 1
  }))
}