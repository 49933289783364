import sbjs from "sourcebuster";
import UAParser from "ua-parser-js";
import Cookies from "js-cookie";

const userAgent = new UAParser().getResult();
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

let utm = {};

try {
  sbjs.init({
    referrals: [
      {
        host: "bing.com",
        medium: "organic",
      },
    ],
  });

  const [utm_term, utm_term_first] = [
    sbjs.get.current.trm || urlParams.get("utm_term") || "",
    sbjs.get.current.trm || "",
  ];

  utm = {
    url: document.URL,
    utm_source: sbjs.get.current.src || urlParams.get("utm_source") || "",
    utm_medium: sbjs.get.current.mdm || urlParams.get("utm_medium") || "",
    utm_keyword: utm_term.split("+").join(" ") || "",
    utm_campaign: sbjs.get.current.cmp || urlParams.get("campaign") || "",
    utm_content: sbjs.get.current.cnt || urlParams.get("utm_content") || "",
    utm_source_first: sbjs.get.first.src || "",
    utm_medium_first: sbjs.get.first.mdm || "",
    utm_keyword_first: utm_term_first.split("+").join(" ") || "",
    utm_campaign_first: sbjs.get.first.cmp || "",
    utm_content_first: sbjs.get.first.cnt || "",
    utm_type: sbjs.get.current.typ || "",
    utm_type_first: sbjs.get.first.typ || "",
    entrance_page: sbjs.get.current_add.ep || "",
    entrance_page_first: sbjs.get.first_add.ep || "",
    visits: sbjs.get.udata.vst || 0,
    device_type: userAgent.device.type || "desktop",
    device_platform: userAgent.os.name || "",
    browser: userAgent.browser.name || "",
    ga_cid: Cookies.get("_ga") || "",
    gclid: Cookies.get("adm_analytics_gclid") || "",
    utm_gclid_first: Cookies.get("adm_analytics_gclid_first") || "",
    utm_mclid: Cookies.get("adm_analytics_msclkid") || "",
    utm_mclid_first: Cookies.get("adm_analytics_msclkid_first") || "",
    utm_fbclid: Cookies.get("adm_analytics_fbclid") || "",
    utm_fbclid_first: Cookies.get("adm_analytics_fbclid_first") || "",
    affiliate_code: Cookies.get("ref_id") || urlParams.get("ref_id") || "",
    raf_referral_code: Cookies.get("raf_code") || "",
  };
} catch (e) {
  console.error(e);
}

export default utm;
