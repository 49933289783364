
import { defineComponent } from "vue";
import { AdmTypography, AdmIcon } from "@/components";

export default defineComponent({
  name: "InfoBox",
  components: {
    AdmTypography,
    AdmIcon,
  },
});
