
import { computed, defineComponent, toRefs, PropType } from "vue";
import { Validation } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { AdmPhone, countryPhones } from "@/components";

export default defineComponent({
  name: "PhoneField",
  components: {
    AdmPhone,
  },
  props: {
    phoneValidation: {
      type: Object as PropType<Validation>,
      required: true,
    },
    codeValidation: {
      type: Object as PropType<Validation>,
      required: true,
    },
    apiErrors: {
      type: Array as PropType<string[]>,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { phoneValidation, apiErrors } = toRefs(props);

    const phoneErrors = computed(() => {
      const errors: { [key: string]: string } = {
        required: t("2-stepSignUpForm.all-step-signup-phone-error-4"),
        invalid: t("2-stepSignUpForm.all-step-signup-phone-error"),
        minLength: t("2-stepSignUpForm.all-step-signup-phone-error-2"),
      };

      let errorCode = "";

      if (phoneValidation.value.$errors.length) {
        errorCode = phoneValidation.value.$errors[0].$validator;
      }

      if (apiErrors.value) {
        errorCode = apiErrors.value[0];
      }

      if (errorCode && errors[errorCode]) {
        return errors[errorCode];
      }

      return null;
    });

    return { phoneErrors, countryPhones };
  },
});
