
import { computed, defineComponent, toRefs, PropType } from "vue";
import { Validation } from "@vuelidate/core";
import { useI18n } from "vue-i18n";
import { AdmInput } from "@/components";

export default defineComponent({
  name: "EmailField",
  components: {
    AdmInput,
  },
  props: {
    emailValidation: {
      type: Object as PropType<Validation>,
      required: true,
    },
    apiErrors: {
      type: Array as PropType<string[]>,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const { emailValidation, apiErrors } = toRefs(props);

    const emailErrors = computed(() => {
      const errors: { [key: string]: string } = {
        required: t("2-stepSignUpForm.all-step-1-signup-email-error-4"),
        email: t("2-stepSignUpForm.all-step-1-signup-email-error"),
        email_disposable: t("2-stepSignUpForm.all-step-signup-general-error-4"),
      };

      let errorCode = "";

      if (emailValidation.value.$errors.length) {
        errorCode = emailValidation.value.$errors[0].$validator;
      }

      if (apiErrors.value) {
        errorCode = apiErrors.value[0];
      }

      if (errorCode && errors[errorCode]) {
        return errors[errorCode];
      }

      return null;
    });

    return { emailErrors };
  },
});
