
import { defineComponent } from "vue";

import { AdmTypography } from "@/components";

export default defineComponent({
  name: "MessageBox",
  components: {
    AdmTypography,
  },
});
