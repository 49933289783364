import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4c263931"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "signup-widget__password" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_adm_input = _resolveComponent("adm-input")!
  const _component_adm_typography = _resolveComponent("adm-typography")!
  const _component_adm_icon = _resolveComponent("adm-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_adm_input, _mergeProps({
      placeholder: "********",
      label: _ctx.$t('2-stepSignUpForm.signup-main-short-password'),
      id: "field_password",
      "force-id": "",
      onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.passwordValidation.$touch())),
      error: _ctx.passwordErrors,
      modelValue: _ctx.modelValue,
      type: _ctx.typePassword ? 'password' : 'text',
      "trailing-icon": _ctx.typePassword ? 'visibility' : 'visibility_off',
      onClickTrailingIcon: _cache[1] || (_cache[1] = ($event: any) => (_ctx.typePassword = !_ctx.typePassword))
    }, _ctx.$attrs), null, 16, ["label", "error", "modelValue", "type", "trailing-icon"]),
    (!!_ctx.modelValue)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_adm_typography, {
            variant: "body2",
            class: "signup-widget__password-label"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("2-stepSignUpForm.all-step-2-signup-password-rule-main")), 1)
            ]),
            _: 1
          }),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.hints, (hint) => {
            return (_openBlock(), _createBlock(_component_adm_typography, {
              key: hint.label,
              class: _normalizeClass([
          hint.valid
            ? 'signup-widget__password-hint-valid'
            : 'signup-widget__password-hint-invalid',
          'signup-widget__password-hint',
        ]),
              variant: "caption",
              tag: "div"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_adm_icon, {
                  icon: hint.valid ? 'check_circle' : 'cancel'
                }, null, 8, ["icon"]),
                _createTextVNode(_toDisplayString(hint.label), 1)
              ]),
              _: 2
            }, 1032, ["class"]))
          }), 128))
        ], 64))
      : _createCommentVNode("", true)
  ]))
}