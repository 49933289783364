import { AxiosInstance } from "axios";
import jstz from "jstz";

import utm from "./utm";

type UnifiedRegisterType = {
  marketing_emails_enabled: boolean;
  country_id: number;
  company_id: number;
  is_test?: boolean;
  entity: string;
  registration_type: string;
  phone_number: string;
  password: string;
  domain_alias: string;
  registration_reason?: string;
  _lang: string;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const registration = (instance: AxiosInstance) => {
  return {
    unified: ({ _lang, ...data }: UnifiedRegisterType) => {
      const formData = {
        accept_terms: true,
        timezone: jstz.determine().name(),
        profile_purpose: 0,
        ...utm,
        ...data,
      };

      return instance.post("/registration/unified/", formData, {
        params: { _lang },
      });
    },
  };
};

export default registration;
