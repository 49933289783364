import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9d4c08da"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "signup-widget__info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_adm_icon = _resolveComponent("adm-icon")!
  const _component_adm_typography = _resolveComponent("adm-typography")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_adm_icon, {
      icon: "info",
      class: "signup-widget__info-icon"
    }),
    _createVNode(_component_adm_typography, {
      variant: "caption",
      class: "signup-widget__info-text"
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ]),
      _: 3
    })
  ]))
}