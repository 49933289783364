<template>
  <div class="signup-widget">
    <first-step
      v-if="currentStep === STEPS.FIRST_STEP"
      @success="currentStep = STEPS.SUCCESS_STEP"
    />
    <success-step v-if="currentStep === STEPS.SUCCESS_STEP" />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import FirstStep from "./FirstStep.vue";
import SuccessStep from "./SuccessStep.vue";

const STEPS = {
  FIRST_STEP: "FIRST_STEP",
  SUCCESS_STEP: "SUCCESS_STEP",
};

export default defineComponent({
  name: "SignupWidget",
  components: { FirstStep, SuccessStep },
  setup() {
    const currentStep = ref(STEPS.FIRST_STEP);

    return { currentStep, STEPS };
  },
});
</script>
