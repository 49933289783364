
import { defineComponent } from "vue";
import { AdmCard, AdmTypography } from "@/components";

export default defineComponent({
  name: "SuccessStep",
  components: {
    AdmTypography,
    AdmCard,
  },
});
