export enum LinkEnum {
  login = "login",
  privacyPolicy = "privacyPolicy",
  contact = "contact",
  homepage = "homepage",
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const makeLink = ({
  homePage,
  language,
  linkName,
  regulator,
}: {
  homePage: string;
  language: string;
  linkName: LinkEnum;
  regulator: string;
}) => {
  const links: Record<LinkEnum, Record<string, string>> = {
    login: {
      default: `${homePage}/${language}/login`,
      en: `${homePage}/login`,
    },
    privacyPolicy: {
      default: `${homePage}/${language}/privacy-policy`,
      en: `${homePage}/privacy-policy`,
      de: `${homePage}/de/datenschutzrichtlinien`,
      fr: `${homePage}/fr/politique-de-confidentialite`,
      nl: `${homePage}/nl/privacybeleid`,
    },
    contact: {
      default: `${homePage}/${language}/about-us/contact-us`,
      en: `${homePage}/about-us/contact-us`,
      de: `${homePage}/de/ueber-uns/kontakt`,
      fr: `${homePage}/fr/a-propos-de-nous/contactez-nous`,
      nl: `${homePage}/nl/over-ons/contact`,
    },
    homepage: {
      default: `${homePage}/${language}`,
      en: homePage,
    },
  };

  return `${
    links[linkName][language] || links[linkName].default
  }?regulator=${regulator}`;
};

export const comLanguages = [
  "en",
  "de",
  "fr",
  "nl",
  "it",
  "ro",
  "es",
  "latam",
  "pt",
  "lv",
  "lt",
  "ee",
  "ru",
  "hu",
  "pl",
  "hr",
  "cz",
  "bg",
  "si",
  "id",
  "ar",
  "bn",
  "km",
  "ko",
];
