
import { defineComponent } from "vue";
import SignupWidget from "./views/SignupWidget/index.vue";

export default defineComponent({
  name: "App",
  components: {
    SignupWidget,
  },
});
